.wrapper {
  padding: 20px;
}

.header {
  padding-bottom: 20px;
}

.title {
  width: min-content;
}

.welcome {
  float: right;
  text-align: right;
}

form div {
  padding-bottom: 5px;
}